<script lang="ts" setup>
import ButtonNavigation from './ButtonNavigation.vue'
import { SwiperNavigation } from '#imports'

interface Banner {
    id: number
    title: string
    imageLink: string
    link: string
    slug: string
}

interface Props {
    banners: Banner[]
}

defineProps<Props>()

const options = {
    modules: [SwiperNavigation],
    loop: true,
    spaceBetween: 30,
    dots: false,
    autoplay: {
        delay: 3000
    },
    navigation: true,
    breakpoints: {
        320: {
            slidesPerView: 1,
            spaceBetween: 8,
            initialSlide: 1
        },
        640: {
            slidesPerView: 3,
            spaceBetween: 30
        }
    }
}

const { localeProperties } = useI18n()

function getTitle(title: string): string[] | string {
    if (!title) return 'no title'

    return title?.split(',')
}

const router = useRouter()

function goToAllNewsletters() {
    router.push({ path: '/insights', hash: '#newsletters' })
}
</script>

<template>
    <div :class="$style.wrapper" class="newsletter-slider">
        <div class="" style="width: 100%">
            <!-- Заголовок (компонент) -->
            <div
                class="prelink__title big-title"
                style="padding-bottom: 16px"
            >
                <AppNuxtLinkLocale
                    :to="{ path: '/insights', hash: '#newsletters' }"
                    :class="$style['name']"
                >
                    {{ $t('newsletter.title') }}
                </AppNuxtLinkLocale>

                <ButtonNavigation
                    :class="$style['link__newsletters']"
                    @click="goToAllNewsletters"
                />
            </div>
        </div>

        <Swiper v-bind="options" :dir="localeProperties.dir">
            <SwiperSlide v-for="banner in banners" :key="banner.id">
                <a
                    :href="banner?.translations[0]?.description"
                    target="_blank"
                    :class="$style['content-wrapper']"
                >
                    <h3 :class="$style.title">
                        <p>{{ getTitle(banner.title)[0] }}</p>
                        <p>{{ getTitle(banner.title)[1] }}</p>
                    </h3>
                    <NuxtImg
                        v-if="banner.imageLink"
                        :class="$style.image"
                        :src="banner.imageLink"
                        alt="image"
                        loading="lazy"
                        width="363px"
                        height="290px"
                    />
                </a>
                <!-- </AppNuxtLinkLocale> -->
            </SwiperSlide>
        </Swiper>
    </div>
</template>

<style lang="scss" module>
.link__newsletters {
    svg {
        path {
            fill: #000;
        }
    }
}

.wrapper {
    width: 100%;
    overflow: hidden;

    @include adaptive-value('margin-top', 100, 30, 1);

    & :global(.big-title) {
        // max-width: 1230px;
        // margin-left: 30px;
        // margin-right: 30px;
    }

    & :global(.swiper) {
        @include adaptive-value('margin-top', 30, 10, 1);
    }

    & :global(.swiper-slide) {
        //padding: 15px;
        //width: 290px !important;

        @include xs-block() {
            //padding: 5px;
        }
    }

    & :global(.swiper-button-next),
    & :global(.swiper-button-prev) {
        width: 56px;
        height: 56px;
        background-color: transparent;
        border: 1px solid #fff;
        border-radius: 50%;
        z-index: 999;
        box-shadow: 0px 4px 4px 1px #00000040;

        @include sm-block() {
            width: 40px;
            height: 40px;
        }

        &:after {
            content: '';
            position: absolute;
            width: 17.23px;
            height: 15px;
            background: url('~/assets/icons/arrow-right-white.svg')
                center center/cover no-repeat;
            // z-index: 999;
        }
    }

    & :global(.swiper-button-prev) {
        @include sm-block() {
            width: 40px;
            height: 40px;
        }

        &:after {
            rotate: 180deg;
        }
    }
}

.content-wrapper {
    display: block;
    position: relative;
    min-height: 290px;
    overflow: hidden;
    width: 100%;
    text-align: center;
    //box-shadow: 5px 4px 8px 0px #000000;
    // margin-bottom: 7px;
    @include sm-block() {
        min-height: 240px;
    }

    @include xs-block() {
        min-height: 180px;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.237265) 23.89%,
            rgba(0, 0, 0, 0.429902) 72.86%,
            rgba(0, 0, 0, 0.59) 100%
        );
    }

    &:hover {
        box-shadow: 5px 4px 8px 0px rgba(0, 0, 0, 0.7);
    }
}

.name {
    font-weight: bold;
    @include adaptive-value('font-size', 36, 20, 1);

    &:hover {
        color: #2d68d9;
        text-shadow: 1px 5px 2px rgba(0, 0, 0, 0.2);
    }
}

.title {
    font-size: 24px;
    font-weight: 600;
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 16px;
    white-space: nowrap;
    @media screen and (max-width: 640px) {
        white-space: initial;
    }
    z-index: $zi-small;

    p {
        color: #fff;
    }
}

.image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
</style>

<script lang="ts" setup>
import { formatNumber } from '../helpers'
import ProjectSvg from '~/public/images/stats/projects.svg?component'
import ClientSvg from '~/public/images/stats/clients.svg?component'
import YearSvg from '~/public/images/stats/years.svg?component'
import ProfessionalsSvg from '~/public/images/stats/professionals.svg?component'
import CountriesSvg from '~/public/images/stats/countries.svg?component'

interface Stat {
    img: string
    number: number
    text: string
}

interface Props {
    stats: Stat[]
}

defineProps<Props>()

const svgIconComponent = computed<{
    [key: string]: any
}>(() => ({
    countries: CountriesSvg,
    years: YearSvg,
    clients: ClientSvg,
    professionals: ProfessionalsSvg,
    projects: ProjectSvg
}))
const roundNumber = (number: number): number => {
    const lastDigit = number % 10
    if (lastDigit >= 5) {
        return number + (10 - lastDigit) // Округление вверх
    } else {
        return number - lastDigit // Округление вниз
    }
}
</script>

<template>
    <div class="stats" dir="ltr">
        <div
            v-for="(stat, index) in stats"
            :key="stat.number"
            class="stats__item"
        >
            <div class="stats__item-title">
                <component
                    :is="svgIconComponent[stat.img]"
                    :class="`stats__${stat.img}-svg`"
                />
                <h4 dir="ltr">
                    <span
                        >{{
                            index > 1
                                ? formatNumber(
                                      roundNumber(stat.number)
                                  )
                                : formatNumber(stat.number)
                        }}
                        <span v-if="index > 1">+</span></span
                    >
                </h4>
            </div>
            <div class="stats__item-text" dir="rtl">
                {{ stat.text }}
            </div>
        </div>
    </div>
</template>

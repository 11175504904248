import { baseURL } from 'nuxt/dist/core/runtime/nitro/paths'

const delay = 1500
// const maxAge = 3600
const baseURL = 'https://gidash.gratanet.com/api'

export async function useGidashQuery(
    queryId: string,
    parameters: any = {}
) {
    const response = await doQuery(queryId, parameters)
    return response.data || []
}

async function doQuery(queryId: string, parameters: any) {
    const response = await useHttpClient(
        `/queries/${queryId}/results`,

        {
            baseURL,
            method: 'POST',
            headers: {
                Authorization:
                    'Key AVtLUg5fYgjBgFrkHMnoeypS7oDfGSFTDX8eIlia'
            },
            body: {
                parameters: {
                    max_age: 14400
                }
            }
        }
    )
    const jobId = response.data.job?.id

    if (jobId) {
        await waitForJob(jobId)
        return await getQueryResult(queryId, parameters)
    }

    return response
}

async function waitForJob(jobId: string) {
    let jobStatus = 0
    while (jobStatus !== 3) {
        const response = await useHttpClient(`/jobs/${jobId}`, {
            baseURL,
            headers: {
                Authorization:
                    'Key AVtLUg5fYgjBgFrkHMnoeypS7oDfGSFTDX8eIlia'
            }
        })
        jobStatus = response.data.value.job.status
        await new Promise(resolve => setTimeout(resolve, delay))
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function getQueryResult(queryId: string, parameters: any) {
    const response = await useHttpClient(
        `/queries/${queryId}/results`,
        {
            baseURL,
            method: 'POST',
            headers: {
                Authorization:
                    'Key AVtLUg5fYgjBgFrkHMnoeypS7oDfGSFTDX8eIlia'
            }
        }
    )
    return response
}

<script setup lang="ts"></script>

<template>
    <div class="transition-loader">
        <div class="transition-logo">
            <span class="svg">
                <SvgoGratawhite filled />
            </span>
        </div>
        <div class="transition-loader-inner">
            <label></label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>
        </div>
    </div>
</template>

<style scoped lang="scss">
.transition-loader {
    z-index: 1040;
    background: rgba(0, 4, 56, 0.99);
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    -webkit-transition: opacity 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out;
}

.transition-logo {
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -100%);
}

//span.svg {
//    line-height: 1;
//    font-size: 0;
//    display: inline-block;
//}
//
.transition-logo svg {
    width: 160px;
    height: 160px;
}

.transition-loader-inner {
    top: 50%;
    position: absolute;
    width: 100%;
    color: #fff;
    padding: 0 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.transition-loader-inner label {
    padding: 1.5em;
    opacity: 0;
    display: inline-block;
    background: #fff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
    animation: loaderMove 1.5s infinite ease-in-out;
}

.transition-loader-inner label:nth-child(1) {
    animation-delay: 0s;
}

.transition-loader-inner label:nth-child(2) {
    animation-delay: 0.2s;
}

.transition-loader-inner label:nth-child(3) {
    animation-delay: 0.4s;
}

.transition-loader-inner label:nth-child(4) {
    animation-delay: 0.6s;
}

.transition-loader-inner label:nth-child(5) {
    animation-delay: 0.8s;
}

.transition-loader-inner label:nth-child(6) {
    animation-delay: 1s;
}

@keyframes loaderMove {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    50% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(-100%);
    }
}
</style>

<script lang="ts" setup>
import type { SwiperOptions } from 'swiper/types'

interface Banner {
    id: number
    title: string
    imageLink: string
    link: string
    imageHide: string
}

interface Props {
    banners: Banner[]
}

defineProps<Props>()

const style = useCssModule()
const animationDuration = 10
const animationDurationK = 0.05

const swiperOptions: SwiperOptions = {
    modules: [
        SwiperAutoplay,
        SwiperEffectCreative,
        SwiperPagination,
        SwiperNavigation
    ],
    slidesPerView: 1,
    loop: true,
    preloadImages: false,

    pagination: {
        el: `.${style['swiper-custom-pagination']}`,
        clickable: true,
        renderBullet: (_: number, className: string) => {
            return `<div class='${className} ${
                style['outer-button']
            }'>
                     <div class='${
                         style.status
                     }' style='animation-duration: ${
                         animationDuration + animationDurationK
                     }s'></div>
        </div>`
        }
    },
    autoplay: {
        delay: animationDuration * 1000,
        disableOnInteraction: false
    },
    effect: 'creative',
    creativeEffect: {
        prev: {
            shadow: false,
            translate: ['-20%', 0, -1]
        },
        next: {
            translate: ['100%', 0, 0]
        }
    }
}
const { localeProperties } = useI18n()

const swiperSlider = ref(null)
const onSwiper = swiper => {
    swiperSlider.value = swiper
}

const nextSlide = () => {
    if (swiperSlider.value) {
        swiperSlider.value.slideNext()
    }
}
const prevSlide = () => {
    if (swiperSlider.value) {
        swiperSlider.value.slidePrev()
    }
}
</script>

<template>
    <div id="sliderHomeIntroId" :class="$style['home-intro-slider']">
        <Swiper
            v-bind="swiperOptions"
            :dir="localeProperties.dir"
            @swiper="onSwiper"
        >
            <SwiperSlide
                v-for="(banner, index) in banners"
                :key="banner.id"
            >
                <div :class="$style['content-wrapper']">
                    <div :class="$style['home-slider-navigation']">
                        <div
                            :class="
                                $style[
                                    'home-slider-navigation__btn-left'
                                ]
                            "
                            @click.stop="prevSlide"
                        ></div>
                        <div
                            :class="
                                $style[
                                    'home-slider-navigation__btn-right'
                                ]
                            "
                            @click.stop="nextSlide"
                        ></div>
                    </div>
                    <div class="container" @click.stop="nextSlide">
                        <div
                            :class="style['title-wrapper']"
                            class="home-page__slide-more"
                        >
                            <div
                                :class="$style.title"
                                class="home-page__slide-title"
                            >
                                <h2>
                                    {{ banner.title }}
                                </h2>
                            </div>
                            <AppNuxtLinkLocale
                                :to="{
                                    path: `${
                                        banner.link ||
                                        '/news/no-source-type'
                                    }`
                                }"
                                class="slide-link-more"
                            >
                                <p>
                                    {{ $t('more') }}
                                </p>

                                <SvgoArrowRightFull
                                    width="13"
                                    height="12"
                                />
                            </AppNuxtLinkLocale>
                        </div>
                    </div>

                    <NuxtImg
                        class=""
                        :class="[
                            $style.image,
                            {
                                'kenburns-top-left':
                                    index === swiperSlider?.realIndex
                            }
                        ]"
                        :src="banner.imageLink"
                        loading="lazy"
                    />
                </div>
            </SwiperSlide>
            <div
                :class="[
                    style['swiper-custom-pagination'],
                    'container'
                ]"
            ></div>
        </Swiper>
    </div>
</template>

<style lang="scss" module>
.home-intro-slider {
    position: relative;

    :global(.swiper-wrapper) {
        max-height: 100vh !important;
        height: calc(100vh - $header-height);
        background-color: #c4c4c4;
    }
}

.home-slider-navigation {
    background-color: rgba($color: #000000, $alpha: 0.25);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 11;
    left: 0;
    top: 0;
    display: flex;
    gap: 2px;

    & > div {
        width: 50%;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}

.content-wrapper {
    position: relative;
    width: 100%;
    height: calc(100vh - $header-height);
    display: flex;
    justify-content: center;
    background-color: rgba(5, 12, 56, 0.25);
    // &:before {
    //     content: '';
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     background-color: rgba(5, 12, 56, 0.25);
    //     z-index: $zi-bigger;
    // }

    :global(.container) {
        position: absolute;
        width: 100%;
        max-width: 1230px;
        padding-left: 30px;
        padding-right: 30px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: $zi-bigest;
        margin: auto;
    }
}

.image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    // position: absolute;
    filter: brightness(0.7);
}

.title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 935px;
}

.slide-link-more {
    background-color: transparent;
    border-color: 1px solid #fff;
    color: #fff;
    @include adaptive-value('padding', 15, 7, 1);
    margin-top: 10px;
}

.title {
    h2 {
        color: #fff;
        // background: rgba($color: #050f38, $alpha: 0.4);
        // box-decoration-break: clone;
        @include adaptive-value('font-size', 54, 18, 1);
        font-weight: 500;
        display: inline;
        line-height: 130%;
        letter-spacing: 0px;
        text-shadow: 6px 7px 5px #00000040;
        max-width: 1104px;
        text-align: left;
        font-weight: 500;
        @include adaptive-value('padding', 0, 7, 1);
        position: relative;

        @include md-block() {
            @include adaptive-value('font-size', 40, 18, 1);
        }

        @include xs-block() {
            line-height: 150%;
        }
    }
}

.swiper-custom-pagination {
    display: flex;
    position: absolute;
    left: 50% !important;
    transform: translateX(-50%);
    top: 20px !important;
    bottom: initial !important;
    z-index: $zi-bigger;
    height: 40px;
    //width: 100%;
}

@keyframes statusAnimation {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

.outer-button {
    flex: 1;
    min-width: 10px !important;
    height: 5px !important;
    background-color: rgba($color: #fff, $alpha: 0.6) !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    position: relative !important;

    @include sm-block() {
        height: 10px !important;
    }

    @include xs-block() {
        height: 5px !important;
    }
}

:global(.swiper-pagination-bullet-active) .status {
    height: 100%;
    background-color: rgba(5, 15, 56, 1) !important;
    position: absolute !important;
    animation: statusAnimation linear;
    animation-duration: v-bind(animationDuration);
    border-radius: 4px;
}
</style>
